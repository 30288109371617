var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"heighRow"},[_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"5%","text-align":"center"}},[(_vm.productItem.billItemType === 1)?_c('span',[_vm._v(_vm._s(_vm.productItem.stt))]):_c('span',[_c('b-form-checkbox',{attrs:{"size":"lg","disabled":true},on:{"change":_vm.checkvalidation},model:{value:(_vm.productItem.checked),callback:function ($$v) {_vm.$set(_vm.productItem, "checked", $$v)},expression:"productItem.checked"}})],1)]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"22%"}},[_c('div',{staticClass:"mt-2 mb-2"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.productItem.code !== ''),expression:"productItem.code !== ''"}],staticClass:"m-0",staticStyle:{"font-weight":"500"}},[_vm._v(" ("+_vm._s(_vm.productItem.code)+") ")]),_c('p',{staticClass:"m-0",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.productItem.name)+" ")]),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.productItem.type === 2),expression:"productItem.type === 2"}]},[_c('b-form-input',{staticClass:"form-control form-control-sm input-imeiCode",attrs:{"placeholder":"Nhập IMEI code","type":"text","aria-describedby":"input-imei-live-feedback","disabled":true},on:{"change":function($event){return _vm.isAvailableQuantity(_vm.productItem.imeiCode)}},model:{value:(_vm.$v.productItem.imeiCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.productItem.imeiCode, "$model", $$v)},expression:"$v.productItem.imeiCode.$model"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.productItem.imeiCode.minLength,"id":"input-imei-live-feedback"}},[_vm._v("Vui lòng nhập imei code ít nhất 5 kí tự")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validate,"id":"input-imei-live-feedback"}},[_vm._v(_vm._s(_vm.messageError))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.productItem.comboItems !== undefined &&
          _vm.productItem.comboItems.length > 0
        ),expression:"\n          productItem.comboItems !== undefined &&\n          productItem.comboItems.length > 0\n        "}],staticClass:"table-responsive"},[_c('table',_vm._l((_vm.productItem.comboItems),function(items,i){return _c('tr',{key:i,staticStyle:{"border-top":"1px solid gray"}},[_vm._m(0,true),_c('td',{staticStyle:{"border":"none","width":"-webkit-fill-available"}},[_vm._v(" "+_vm._s(items.productName)+" "),_c('i',{staticStyle:{"font-size":"unset","color":"unset"}},[_vm._v("("+_vm._s(_vm.formatMoney(items.sellingPrice))+")")])]),_c('td',{staticStyle:{"border":"none"}},[_vm._v(_vm._s(items.quantity))])])}),0)])],1)]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"8%","text-align":"right"}},[(
        (_vm.productItem.type === 1 ||
          _vm.productItem.type === 3 ||
          _vm.productItem.type === 4) &&
        _vm.productItem.billItemType === 1
      )?_c('div',[(_vm.editable && _vm.productItem.billItemType === 1)?_c('b-form-input',{staticClass:"w-100 h-90",staticStyle:{"height":"calc(1.35rem + 1.1rem + 2px)","border-color":"#007bff","text-align":"right"},attrs:{"type":"number","placeholder":"Số lượng"},on:{"change":_vm.checkvalidation},model:{value:(_vm.productItem.quantity),callback:function ($$v) {_vm.$set(_vm.productItem, "quantity", $$v)},expression:"productItem.quantity"}}):_c('span',[_vm._v(_vm._s(_vm.productItem.quantity))])],1):_c('span',[_vm._v(_vm._s(_vm.productItem.quantity))])]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"15%","text-align":"right"}},[_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.price)))])]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"8%","text-align":"right"}},[(_vm.productItem.billItemType === 1)?_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.allotmentDiscount)))]):_vm._e()]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"8%","text-align":"right"}},[(_vm.productItem.billItemType === 1)?_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.allotmentPointAmount)))]):_vm._e()]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"12%","text-align":"right"}},[(_vm.productItem.billItemType === 1)?_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.totalPriceOfProduct)))]):_c('div',{staticClass:"mb-2"},[(
          _vm.productItem.billItemType === 2 || _vm.productItem.billItemType === 3
        )?_c('div',[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.productItem.billItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu'))]),_c('br'),(_vm.editable)?_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"w-100 h-90",staticStyle:{"height":"calc(1.35rem + 1.1rem + 2px)","border-color":"#007bff","text-align":"right"},attrs:{"size":"sm","placeholder":_vm.productItem.billItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu',"type":"text","min":1},on:{"change":_vm.checkvalidation},model:{value:(_vm.productItem.returnPrice),callback:function ($$v) {_vm.$set(_vm.productItem, "returnPrice", $$v)},expression:"productItem.returnPrice"}}):_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.returnPrice)))])],1):_vm._e()])]),_c('td',{staticStyle:{"padding":"0 5px 0 5px","width":"17%","text-align":"right"}},[(_vm.editable && _vm.productItem.billItemType === 1)?_c('div',[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-dropdown',{attrs:{"text":_vm.productItem.discountType == _vm.DISCOUNT_AMOUNT_TYPE.MONEY
                ? '$'
                : _vm.productItem.discountType == _vm.DISCOUNT_AMOUNT_TYPE.PERCENT
                ? '%'
                : '',"size":"sm","variant":"outline-primary"},on:{"change":_vm.checkvalidation}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onClickDiscountType(1)}}},[_vm._v(_vm._s('$'))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onClickDiscountType(2)}}},[_vm._v(_vm._s('%'))])],1)]},proxy:true}],null,false,3295317547)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"w-90 h-90",staticStyle:{"height":"calc(1.35rem + 1.1rem + 2px)","border-color":"#007bff","text-align":"right"},attrs:{"size":"sm","placeholder":"Chiết khấu","type":"text","min":1},on:{"change":_vm.checkvalidation},model:{value:(_vm.productItem.discount),callback:function ($$v) {_vm.$set(_vm.productItem, "discount", $$v)},expression:"productItem.discount"}})],1)],1):_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.discount))+_vm._s(_vm.discountTypeText))])]),(_vm.editable)?_c('td',{staticStyle:{"width":"5%"}},[[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger",attrs:{"small":"","title":"Xóa"},on:{"click":_vm.showDeleteAlert}},[_vm._v("mdi-delete")])],1)]],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"border":"none"}},[_c('i',{staticClass:"fas fa-cube",staticStyle:{"color":"rgb(24, 28, 50)"}})])}]

export { render, staticRenderFns }